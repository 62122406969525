exports.components = {
  "component---src-pages-about-tenbin-game-js": () => import("./../../../src/pages/aboutTenbinGame.js" /* webpackChunkName: "component---src-pages-about-tenbin-game-js" */),
  "component---src-pages-commerce-js": () => import("./../../../src/pages/commerce.js" /* webpackChunkName: "component---src-pages-commerce-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-remove-method-js": () => import("./../../../src/pages/remove_method.js" /* webpackChunkName: "component---src-pages-remove-method-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

